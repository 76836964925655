<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>{{actionTitle}} external contact</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                  {{title}}
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <form @submit.vent="createOrUpdate()">
              <v-row>
              <v-col cols="12" sm="12" md="12">
                <h4>Contact details</h4>
              </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="addressBook.first_name"
                      outlined :error="$v.addressBook.first_name.$error"
                      dense>
                    <template v-slot:label>
                      First name <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="errors.first_name" >{{errors.first_name[0]}}</span>

                  <span class="text-danger" v-if="$v.addressBook.first_name.$error">This information is required</span>

                </v-col>
<!--                <v-col cols="12" md="4">-->
<!--                  <v-text-field-->
<!--                      v-model="addressBook.middle_name"-->
<!--                      outlined-->
<!--                      dense>-->
<!--                    <template v-slot:label>-->
<!--                      Middle name-->
<!--                    </template>-->
<!--                  </v-text-field>-->
<!--                </v-col>-->
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="addressBook.last_name"
                      outlined :error="$v.addressBook.last_name.$error"
                      dense>
                    <template v-slot:label>
                     Surname <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.addressBook.last_name.$error">This information is required</span>
                  <span class="text-danger" v-if="errors.last_name" >{{errors.last_name[0]}}</span>

                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="addressBook.email"
                      outlined :error="$v.addressBook.email.$error || !$v.addressBook.email.email"
                      dense>
                    <template v-slot:label>
                      Email <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.addressBook.email.$error">This information is required</span>
                  <span class="text-danger" v-if="!$v.addressBook.email.email">This information is not valid</span>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="addressBook.occupation"
                      outlined
                      dense>
                    <template v-slot:label>
                      Occupation
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="addressBook.website"
                      outlined
                      dense>
                    <template v-slot:label>
                      Website
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="6" v-if="currentUser.access_type!='score'">

                  <v-select label="SCORE*" clearable
                            outlined
                            dense item-text="name" :items="scores"
                            item-value="id" :error="$v.addressBook.score_id.$error"
                            v-model="addressBook.score_id">
                    <template v-slot:label>
                      SCORE <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.addressBook.score_id.$error">SCORE</span>

                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="addressBook.phone"
                      outlined :error="$v.addressBook.phone.$error"
                      dense>
                    <template v-slot:label>
                      Phone <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.addressBook.phone.$error">This information is required</span>

                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="addressBook.mobile"
                      outlined
                      dense>
                    <template v-slot:label>
                     Mobile
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="addressBook.work_phone"
                      outlined
                      dense>
                    <template v-slot:label>
                      Work phone
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <h4>Address details</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="addressBook.address_line_1"
                      outlined :error="$v.addressBook.address_line_1.$error"
                      dense>
                    <template v-slot:label>
                      Address line 1 <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.addressBook.address_line_1.$error">TThis information is required</span>

                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="addressBook.address_line_2"
                      outlined
                      dense>
                    <template v-slot:label>
                      Address line 2
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="addressBook.suburb"
                      outlined :error="$v.addressBook.suburb.$error"
                      dense>
                    <template v-slot:label>
                      Suburb <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.addressBook.suburb.$error">This information is required</span>

                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="addressBook.post_code"
                      outlined  :error="$v.addressBook.post_code.$error"
                      dense>
                    <template v-slot:label>
                      Post code <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.addressBook.post_code.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete @change="toggleCountry"
                                  v-model="addressBook.country_id"
                                  :items="countries" filled
                                  outlined
                                  dense color="blue-grey lighten-2"
                                  label="Country"
                                  item-text="name"
                                  item-value="id">

                  </v-autocomplete>

                </v-col>
                <v-col cols="12" sm="12" md="6" v-if="toggle.stateList==false">
                  <v-text-field label="State*" outlined dense :error="$v.addressBook.state.$error"
                                v-model="addressBook.state">
                    <template v-slot:label>
                     State <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.addressBook.state.$error">This information is required</span>

                </v-col>
                <v-col cols="12" sm="12" md="6" v-if="toggle.stateList">

                  <v-autocomplete
                      v-model="addressBook.state_id"
                      :items="states"
                      filled
                      outlined :error="$v.addressBook.state_id.$error"
                      dense color="blue-grey lighten-2"
                      label="States"
                      item-text="name"
                      item-value="id">
                      <template v-slot:label>
                        State <span class="text-danger">*</span>
                      </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.addressBook.state_id.$error">This information is required</span>

                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <label for="">Comments</label>
                  <ckeditor :config="editorConfig"
                            v-model="addressBook.remarks" >
                  </ckeditor>
                </v-col>
                <v-col cols="6" md="4">
                  Status
                  <v-switch
                      v-model="addressBook.is_active"
                      :label="addressBook.is_active?' Active ':'Inactive'"
                      color="primary"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="text-right">
                  <v-btn color="btn btn-primary mr-2" dark x-large @click="createOrUpdate()" :loading="loading">
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import AddressBookService from "@/services/address-book/AddressBookService";
import ScoreService from "@/services/score/score/ScoreService";
import CountryService from "@/services/country/CountryService";
import StateService from "@/services/setting/state/StateService";
import {required,email,numeric,requiredIf} from "vuelidate/lib/validators";

const state =new StateService;
const country=new CountryService();
const score = new ScoreService();
const addressBook = new AddressBookService;
export default {
  validations:{
    addressBook:{
      first_name:{required},
      last_name:{required},
      email:{required,email},
      phone:{required},
      score_id:{
        required: requiredIf(function (nestedModel){
          return this.currentUser.access_type!='score';
        }),
      },
      address_line_1:{required},
      suburb:{required},
      post_code:{required},
      state: {
        required: requiredIf(function (nestedModel) {
          return this.toggle.stateList==false;
        })
      },
      state_id:{
        required: requiredIf(function (nestedModel) {
          return this.toggle.stateList==true;
        })
      },
    },
  },
  data(){
    return{
      actionTitle:'',
      loading:false,
      countries:[],
      states:[],
      scores:[],
      addressBook:{
        first_name:'',
        middle_name:'',
        last_name:'',
        occupation:'',
        score_id:'',
        email:'',
        website:'',
        address_line_1:'',
        address_line_2:'',
        post_code:'',
        suburb:'',
        country_id:14,
        state:'',
        state_id:'',
        remarks:'',
        is_active:true,
      },
      toggle:{
        stateList:true
      },
      editorConfig: {
        scayt_autoStartup: true,
        scayt_sLang: 'en_GB',
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
      currentUser:{},
      errors:[],
      title: ''
    }
  },
  methods:{
    toggleCountry(){

      if(this.addressBook.country_id != 14){
        this.toggle.stateList=false;
      }else{
        this.toggle.stateList=true;
      }
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (this.addressBookId != null || this.addressBookId != undefined) {
          addressBook
              .update(this.addressBookId, this.addressBook)
              .then(response => {
                this.$snotify.success("External contact updated");
                this.loading = false;
                setTimeout(() => {
                  this.$tabs.close();
                }, 1000);
              })
              .catch(error => {
                this.loading = false;
                this.errors = error.errors;
              })
        } else {
          this.loading = true;
          addressBook
              .create(this.addressBook)
              .then(response => {
                this.$snotify.success("External contact added");
                this.loading = false;
                this.$tabs.refresh('/address-book')
                this.$emit('refresh',response.data.addressBook);
                this.back();
              })
              .catch(error => {
                this.loading = false;
                this.errors = error.errors;
              })
        }
      }
    },
    back() {
      this.$tabs.close();
      this.$router.push({
        name: "address-book",
      });
    },
    getAllScore(){
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    getAllState()
    {
      state
          .all()
          .then(response => {
            this.states = response.data.data;
          })
          .catch((err) => {

          });
    },
    getCountries(){
      country
          .getAllCountry()
          .then((response) => {

            this.countries = response.data.data;
          })
          .catch((error) => {

          })
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    addressBookDetail() {
      if(this.addressBookId != null || this.addressBookId !=undefined ) {
        this.actionTitle = 'Edit';
        addressBook
            .show(this.addressBookId)
            .then(response=> {
              this.addressBook = response.data.addressBook;
              this.toggleCountry();
            })
        }else {
          this.actionTitle = 'New';
          this.addressBook = {
          first_name: '',
          middle_name: '',
          last_name: '',
          score_id: '',
          email: '',
          address_line_1: '',
          address_line_2: '',
          post_code: '',
          suburb: '',
          country_id: 14,
          state: '',
          state_id: '',
          remarks: '',
          is_active: true,
        };
      }
    }
  },
  mounted() {
    this.getCurrentUser();
    this.getAllState();
    this.getAllScore();
    this.getCountries();
    this.addressBookDetail();
  },
  computed:{
    addressBookId() {
      return this.$route.params.addressBookId;
    },
    routeTab(){
      if(this.addressBook){
        let title = this.addressBook.first_name ? this.addressBook.first_name : 'New ' + 'contact'
        this.title = title;
        return {
          title: title
        }
      }
    }
  }

}
</script>